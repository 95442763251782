type AccountFeature = {
  id: number
  formatted_active_until: string
  in_app_purchase_id: number
}

type InAppPurchase = {
  id: number
  activated: boolean
  category: 'student' | 'teoriakoeharjoittelu' | 'teacher' | 'ebook'
  description: string
  price: number
  price_string: string
  title: string
  valid_for_days: number
  account_feature_category: {
    id: number
    name: string
  }
  driving_license_classes: {
    id: number
    name: string
    icon_url: string
  }[]
  language_ids: number[]
  vat_rate: {
    percentage_string: string
  }
}

type Languages = {
  id: number
  name: string
  flag_url: string
}

type UpdationSubmissionResponse = {
  features_activated: AccountFeature[]
  feature_ids_deactivated: number[]
  msg: string
}

export default (
  driverId: number,
  inAppPurchases: InAppPurchase[],
  activatedFeatures: AccountFeature[],
  licenseIds: number[],
  languageIds: number[],
  languages: Languages
) => ({
  driverId,
  inAppPurchases,
  activatedFeatures,
  languages,

  inAppPurchasesToUpdate: new Set() as Set<InAppPurchase>,

  categoryIds: [] as number[],
  languageIds,
  licenseIds,
  showFilterMenu: false,
  status: 'all' as 'all' | 'active' | 'inactive',

  priceFormatter: new Intl.NumberFormat(window.lang, { style: 'currency', currency: 'EUR' }),

  init() {
    for (const iap of inAppPurchases) {
      iap.activated = this.isActivatedInAppPurchase(iap)
      iap.price = +iap.price
    }
  },

  get activeFiltersCount(): number {
    return this.licenseIds.length + this.languageIds.length + this.categoryIds.length + (this.status === 'all' ? 0 : 1)
  },

  get notTeoriakoeProducts(): Record<string, InAppPurchase[]> {
    return this.filteredProducts(this.inAppPurchases.filter((iap) => iap.category !== 'teoriakoeharjoittelu')).reduce(
      (acc, curr) => {
        const categoryName = curr.account_feature_category.name
        acc[categoryName] ||= []
        acc[categoryName].push(curr)
        return acc
      },
      {} as Record<string, InAppPurchase[]>
    )
  },

  get teoriakoeProducts(): InAppPurchase[] {
    return this.filteredProducts(this.inAppPurchases.filter((iap) => iap.category === 'teoriakoeharjoittelu')).sort(
      (a, b) => (a.driving_license_classes[0]?.name || '').localeCompare(b.driving_license_classes[0]?.name || '')
    )
  },

  get totalChosenCount(): number {
    return this.inAppPurchasesToUpdate.size
  },

  get totalChosenPrice(): string {
    const total = this.toActivateProducts.reduce((acc, cur) => acc + cur.price, 0)

    return this.priceFormatter.format(total)
  },

  get toActivateProducts(): InAppPurchase[] {
    return [...this.inAppPurchasesToUpdate].filter((iap) => !iap.activated)
  },

  get toDeactivateProducts(): InAppPurchase[] {
    return [...this.inAppPurchasesToUpdate].filter((iap) => iap.activated)
  },

  activeUntilDate(iap: InAppPurchase): string {
    return this.activatedFeatures.find((f) => f.in_app_purchase_id === iap.id)?.formatted_active_until || ''
  },

  cardClass(iap: InAppPurchase): 'card-red' | 'card-blue' | 'card-green' | undefined {
    if (this.inAppPurchasesToUpdate.has(iap) && iap.activated) return 'card-red'
    if (this.inAppPurchasesToUpdate.has(iap) && !iap.activated) return 'card-blue'
    if (iap.activated) return 'card-green'
  },

  filteredProducts(iaps: InAppPurchase[]): InAppPurchase[] {
    return iaps.filter((iap) => {
      const licenseVisible =
        !this.licenseIds.length ||
        !iap.driving_license_classes.length ||
        this.licenseIds.some((id) => iap.driving_license_classes.find((l) => l.id === id))
      const languageVisible = !this.languageIds.length || this.languageIds.some((id) => iap.language_ids.includes(id))
      const categoryVisible = !this.categoryIds.length || this.categoryIds.includes(iap.account_feature_category.id)
      const isActivated = this.isActivatedInAppPurchase(iap)
      const statusVisible =
        this.status === 'all' ||
        (this.status === 'active' && isActivated) ||
        (this.status === 'inactive' && !isActivated)

      return licenseVisible && languageVisible && categoryVisible && statusVisible
    })
  },

  async handleSelectedFeaturesSubmission(): Promise<void> {
    try {
      const featureIdsToDeactivate = this.activatedFeatures
        .filter((f) => this.toDeactivateProducts.map((iap) => iap.id).some((id) => id === f.in_app_purchase_id))
        .map((f) => f.id)
      const body = JSON.stringify({
        account_feature: {
          driver_id: this.driverId,
          in_app_purchase_ids_to_activate: this.toActivateProducts.map((iap) => iap.id),
          feature_ids_to_deactivate: featureIdsToDeactivate,
        },
      })
      const headers = { 'Content-Type': 'application/json' }
      const res = await fetch('/teacher/account_features/update_student_features', { method: 'PATCH', body, headers })
      if (!res.ok) throw new Error()

      const { features_activated, feature_ids_deactivated, msg } = (await res.json()) as UpdationSubmissionResponse

      if (feature_ids_deactivated.length) {
        this.activatedFeatures = this.activatedFeatures.filter((f) => !feature_ids_deactivated.includes(f.id))
      }
      this.activatedFeatures.push(...features_activated)

      for (const iap of this.inAppPurchases) {
        iap.activated = this.isActivatedInAppPurchase(iap)
      }

      this.inAppPurchasesToUpdate.clear()

      flash('notice', msg)
    } catch (error) {
      alert(I18n.unknown_error)
    }
  },

  isActivatedInAppPurchase(iap: InAppPurchase): boolean {
    return this.activatedFeatures.some((f) => f.in_app_purchase_id === iap.id)
  },

  removeAllFilters(): void {
    this.licenseIds = []
    this.languageIds = []
    this.categoryIds = []
    this.status = 'all'
  },

  toggleFeature(iap: InAppPurchase): void {
    if (this.inAppPurchasesToUpdate.has(iap)) {
      this.inAppPurchasesToUpdate.delete(iap)
    } else {
      this.inAppPurchasesToUpdate.add(iap)
    }
  },

  truncateString(str: string, length: number, ending = '...'): string {
    return str.length > length ? str.slice(0, length - ending.length) + ending : str
  },
})
