export const billingInvoices = (type) => ({
  type,

  init() {
    const form = this.$root.querySelector('form')

    // Fetch invoices again when changing tabs
    // This ensures that any payments added in the other tab will be reflected here
    document.addEventListener('viewInvoices', () => form.requestSubmit())

    // Load initial data
    form.requestSubmit()
  },

  get dateInfo() {
    switch (this.type) {
      case 'overdue':
        return I18n.due_date_info
      case 'cash_payments':
      case 'paid':
      case 'with_payments':
        return I18n.paid_date_info
      default:
        return I18n.invoice_date_info
    }
  },
})

export const billingPayments = () => ({
  dateInfo: I18n.paid_date_info,

  init() {
    const form = this.$root.querySelector('form')
    document.addEventListener('viewPayments', () => form.requestSubmit())
  },
})

export const referenceTransactions = () => ({
  filename: I18n.no_file,

  init() {
    const form = this.$root
    form.addEventListener('ajax:success', ({ detail }) => {
      const resultContainer = document.querySelector('#reference-transaction-results')
      resultContainer.innerHTML = detail[2].responseText
    })

    form.addEventListener('ajax:error', ({ detail }) => {
      const errorMsg = detail[2].responseText
      flash('alert', errorMsg)
    })
  },

  uploadFile({ target }) {
    this.filename = target.files[0]?.name || I18n.no_file
  },
})

export const billingReports = (reportType) => ({
  reportType,

  get dateInfo() {
    return ['vat_report_paid', 'paid_invoices'].includes(this.reportType) ? I18n.paid_date_info : I18n.invoice_date_info
  },
})

export const reportsTable = () => ({
  init() {
    const dataTableOptions = {
      tableTools: { sRowSelect: 'multi', sSelectedClass: 'row-selected' },
      lengthMenu: [[10, 25, 50, -1]],
      order: [0, 'desc'],
      dom: 'Bfrtip',
      buttons: [
        'csv',
        'copy',
        {
          // Customize the print output
          extend: 'print',
          customize: function (win) {
            $(win.document).find('table').css('font-family', 'Arial, sans-serif')
            $(win.document).find('td, th').css('padding-inline', '10px')
            $(win.document).find('h1').css('text-align', 'center')
          },
        },
      ],
      responsive: false,
      columnDefs: [{ className: 'dt-center', targets: '_all' }],
      retrieve: true,
      bDestroy: true,
    }

    const $reportsTable = $(this.$root)
    $reportsTable.DataTable(dataTableOptions)
  },
})
