export default (division, divisions) => ({
  init() {
    function changeOpenClasses(classes) {
      if (!classes) classes = []
      for (let e of document.getElementsByName("driving_test_question[driving_license_class_ids][]")) {
        if (!e.closest('label')) continue
        if (classes.includes(parseInt(e.value))) {
          e.closest('label').hidden = false
        } else {
          e.closest('label').hidden = true
          e.checked = false
        }
      }
    }

    changeOpenClasses(divisions[division])
    document.getElementById("driving_test_question_driving_test_division_id").change = function() {
      changeOpenClasses(divisions[this.value])
    }
    document.getElementById("licenses").hidden = false
  }
})
