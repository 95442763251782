import type { AlpineComponent } from 'alpinejs'

// Make an ajax request to the specified url after a confirmation and reload the page if the request was successful
// Requires data-title and data-text on the element for the confirmation dialog
// An optional data-warning parameter adds an additional check to the confirmation dialog
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
export default (method: HttpMethod, url: string | null = null, reload = true): AlpineComponent<{}> => ({
  init() {
    const button = this.$root
    const { title, text, warning } = button.dataset
    const headers = new Headers()
    headers.set('Content-Type', 'application/json')

    if (button instanceof HTMLAnchorElement) {
      url ||= button.href
    }

    const callback = async () => {
      if (!url) {
        console.error('No URL provided')
        return
      }

      try {
        const res = await fetch(url, { method, headers })
        if (!res.ok) throw new Error()

        if (reload) {
          location.reload()
          return
        }

        const { msg } = await res.json()
        msg && window.flash('notice', msg)
        button.closest('tr')?.remove()
      } catch (error) {
        alert(window.I18n.unknown_error)
      }
    }

    button.addEventListener('click', (e) => {
      e.preventDefault()
      window.warningModal(title, text, callback, null, warning)
    })
  },
})
