export default (products, unpaidSum, creditInvoice) => ({
  products,
  unpaidSum,
  creditInvoice,
  currencyFormatter: new Intl.NumberFormat(window.lang, { style: 'currency', currency: 'EUR' }),
  productIndex: 0,
  step: 1,

  init() {
    this.products = this.products.map((product) => {
      product.price = +product.price
      product.quantity = +product.quantity
      product.originalPrice = product.id ? 0 : -this.totalPrice(product)
      product.discount = product.discount ? +product.discount : null
      product.index = this.productIndex++
      return product
    })

    this.$root.addEventListener('ajax:success', ({ detail }) => {
      if (!this.creditInvoice.id) {
        this.step++
        this.creditInvoice.id = detail[0].id
      } else {
        this.$root.closest('dialog').remove()
        flash('notice', detail[0].msg)
        this.renderRow()
      }
    })
  },

  get totalRefund() {
    const products = this.products.filter((p) => !p._destroy)
    const productTotal = products.reduce((acc, product) => acc + this.totalPrice(product), 0)
    return this.roundedEuros(productTotal)
  },

  get totalVat() {
    // VAT is calculated from total prices grouped by VAT rate
    const totalsByVatRate = this.products
      .filter((p) => !p._destroy)
      .reduce((acc, product) => {
        acc[product.vat_percentage] ||= 0
        acc[product.vat_percentage] += this.totalPrice(product)
        return acc
      }, {})

    // Calculate the amount of included VAT by VAT rate if prices include VAT, or calculate the VAT that needs to be added if they don't
    // Formulas from vero.fi
    const totalVat = Object.entries(totalsByVatRate).reduce((acc, [vatPercentage, total]) => {
      vatPercentage = +vatPercentage
      const includedVat = (vatPercentage * total) / (100 + vatPercentage)
      const addedVat = vatPercentage * (total / 100)
      const totalVat = this.creditInvoice.prices_incl_vat ? includedVat : addedVat

      return this.roundedEuros(acc + totalVat)
    }, 0)

    return this.roundedEuros(totalVat)
  },

  get unpaidAfterRefund() {
    const vat = this.creditInvoice.prices_incl_vat ? 0 : this.totalVat
    return this.roundedEuros(this.unpaidSum + this.totalRefund + vat)
  },

  discount(product) {
    switch (product.discount_type) {
      case 'none':
        return 0
      case 'amount':
        return -(product.discount || 0)
      case 'percentage':
        const discountPercentage = product.discount || 0
        const totalBeforeDiscount = product.price * product.quantity
        return (totalBeforeDiscount / 100) * discountPercentage
    }
  },

  productInputName(attributeName) {
    return `credit_invoice[invoice_products_attributes][${this.product.index}][${attributeName}]`
  },

  removeProduct(product) {
    if (product.id) {
      product._destroy = true
      return
    }

    this.products = this.products.filter((p) => p !== product)
  },

  renderEuros(sum) {
    return this.currencyFormatter.format(sum)
  },

  roundedEuros(sum) {
    return Math.round(sum * 100) / 100.0
  },

  totalPrice(product) {
    return this.roundedEuros(product.price * product.quantity - this.discount(product))
  },

  async markAsSent() {
    const url = `/teacher/invoices/${this.creditInvoice.id}/mark_as_sent`
    loadingSpinner(true)

    try {
      const res = await fetch(url, { method: 'POST' })
      if (!res.ok) throw new Error()
      const msg = await res.text()
      flash('notice', msg)
      this.renderRow()
      this.$el.closest('dialog').remove()
    } catch {
      alert('Something went wrong')
    }

    loadingSpinner(false)
  },

  async sendEmail() {
    const url = `/teacher/invoices/${this.creditInvoice.id}/send_email`
    loadingSpinner(true)

    try {
      const res = await fetch(url, { method: 'POST' })
      const msg = await res.text()

      if (res.ok) {
        flash('notice', msg)
        this.renderRow()
        this.$el.closest('dialog').remove()
      } else {
        flash('alert', msg)
      }
    } catch {
      alert('Something went wrong')
    }

    loadingSpinner(false)
  },

  // Update the row in the credit invoice table
  async renderRow() {
    if (window.location.pathname.split('/').at(-1) === 'edit') {
      return
    }

    try {
      const res = await fetch(`/teacher/credit_invoices/${this.creditInvoice.id}`)
      const html = await res.text()
      const rows = document.querySelector('#credit-invoice-rows')
      const row = rows?.querySelector(`[data-credit-invoice-id="${this.creditInvoice.id}"]`)

      if (row) {
        row.outerHTML = html
      } else if (rows) {
        rows.insertAdjacentHTML('afterbegin', html)
      }

      this.renderInvoiceRows()
    } catch {
      alert('Something went wrong')
    }
  },

  // Update the rows in the invoice table (if present)
  async renderInvoiceRows() {
    const { id, original_invoice_id } = this.creditInvoice
    for (const invoiceId of [id, original_invoice_id]) {
      const originalRow = document.querySelector(`#invoice-row-${invoiceId}`)
      if (!originalRow) continue

      const rowType = originalRow.dataset.rowType
      const res = await fetch(`/teacher/invoices/${invoiceId}/invoice_row?row_type=${rowType}`)
      originalRow.outerHTML = await res.text()
    }
  },
})
