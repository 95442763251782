// Requires 3 x-refs: 'sortByInput', 'sortDirectionInput' and 'sortForm'
export const tableSort = (sortBy, sortDirection) => ({
  sortBy,
  sortDirection,

  sort(attribute) {
    const attrChanged = this.sortBy !== attribute
    const oppositeDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    this.sortBy = attribute
    this.sortDirection = attrChanged ? 'asc' : oppositeDirection
    this.submit()
  },

  // Inputs that are bound to values with `:value` won't get filled before the form submission happens
  // Setting the input values manually before submitting the form ensures that the values are correct
  submit() {
    this.$refs.sortByInput.value = this.sortBy
    this.$refs.sortDirectionInput.value = this.sortDirection
    this.$refs.sortForm.requestSubmit()
  },
})

// x-bind to sortable table headers
export const SortHeader = (attr) => ({
  ':class'() {
    return this.sortBy === attr ? `sortable ${this.sortDirection}` : 'sortable'
  },

  '@click'() {
    this.sort(attr)
  },
})
