// Copies text from an input to the user's clipboard
// Required elements: x-ref="copyTarget" OR a string as input, @click="copy"
export default (text = null) => ({
  async copy() {
    try {
      await navigator.clipboard.writeText(text || this.$refs.copyTarget.value)
      flash('notice', I18n.copied_to_clipboard)
    } catch (error) {
      console.error(error)
    }
  },
})
