export default (invoiceId, payments, paid, invoiceTotal, paymentMethodTranslations) => ({
  payments,
  paid,
  invoiceTotal,
  paymentMethodTranslations,
  newPayment: {},
  currencyFormatter: new Intl.NumberFormat(window.lang, { style: 'currency', currency: 'EUR' }),

  init() {
    this.initializePayment()
  },

  addPayment({ detail }) {
    const { payment, paid } = detail[0]
    payment.paid_sum = +payment.paid_sum
    this.payments.push(payment)
    this.paid = paid
    this.initializePayment()
    this.updatePage()
  },

  // Reload the full page in the edit view, replace table row in index view
  async updatePage() {
    const reload = window.location.pathname.split('/').at(-1) === 'edit'

    if (reload) {
      this.paid && window.location.reload()
      return
    }

    const originalRow = document.querySelector(`#invoice-row-${invoiceId}`)
    const rowType = originalRow.dataset.rowType
    const res = await fetch(`/teacher/invoices/${invoiceId}/invoice_row?row_type=${rowType}`)
    originalRow.outerHTML = await res.text()
  },

  renderEuros(sum) {
    return this.currencyFormatter.format(sum)
  },

  initializePayment() {
    const { newPayment } = this
    newPayment.payment_method = 'bank_transfer'
    newPayment.paid_sum = this.remainingSum

    // Reset the date on the flatpickr instance after submitting the form (altInput messes up manual updates on the underlying "real" input)
    document.querySelector('#invoice_payment_pay_date')?._flatpickr?.setDate(new Date())
  },

  async updateStatus(newStatus) {
    const url = `/teacher/invoices/${invoiceId}`
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify({ invoice_state: newStatus })
    loadingSpinner(true)

    try {
      const res = await fetch(url, { method: 'PATCH', headers, body })

      if (res.ok) {
        loadingSpinner(false)
        this.paid = newStatus === 'paid'
        return this.updatePage()
      }

      const errorHTML = await res.text()
      renderFormErrors(errorHTML, this.$el.closest('form'))
    } catch {
      alert('Something went wrong')
    }

    loadingSpinner(false)
  },

  removePayment({ detail }) {
    const { id, msg } = detail[0]
    this.payments = this.payments.filter((p) => p.id !== id)
    this.updatePage()
    flash('notice', msg)
  },

  get remainingSum() {
    return Math.round((this.invoiceTotal - this.totalPaid) * 100) / 100
  },

  get sortedPayments() {
    return this.payments.sort((a, b) => {
      if (a.pay_date < b.pay_date) return 1
      if (a.pay_date > b.pay_date) return -1
      return a.id < b.id ? 1 : -1
    })
  },

  get totalPaid() {
    return this.payments.reduce((acc, p) => acc + p.paid_sum * 100, 0) / 100
  },
})
