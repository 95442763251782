export const courseOutline = (examIds, modules) => ({
  examIds,
  modules,
  showExams: false,
  subjectIndex: 0,

  init() {
    this.modules.forEach((module) => {
      module.lesson_subjects.forEach((subject) => {
        subject.index = this.subjectIndex++
      })
    })
    this.showExams = this.examIds.length > 0
  },

  toggleExams() {
    this.showExams = !this.showExams
    if (!this.showExams) {
      this.examIds = []
    }
  },
})

export const studyModule = (category, categoryNames) => ({
  category,
  categoryNames,

  get module() {
    return this.modules.find((module) => module.category === this.category)
  },

  get activeSubjects() {
    return this.module.lesson_subjects.filter((s) => !s._destroy)
  },

  activateModule() {
    const { module, category } = this
    module
      ? (module._destroy = false)
      : this.modules.push({ category, lesson_subjects: [], required_lessons: null, max_simulator_lessons: null })
  },

  removeModule() {
    const { module } = this
    module.id ? (module._destroy = true) : (this.modules = this.modules.filter((m) => m.category !== module.category))
  },

  addSubject() {
    const newSubject = {
      index: this.subjectIndex++,
      position: this.activeSubjects.length,
      name_locales: {},
      required_lessons: null,
    }
    this.module.lesson_subjects.push(newSubject)
  },

  removeSubject(subject) {
    const nextSubjects = this.module.lesson_subjects.filter((s) => s.position > subject.position)
    nextSubjects.forEach((s) => s.position--)

    subject.id
      ? (subject._destroy = true)
      : (this.module.lesson_subjects = this.module.lesson_subjects.filter((s) => s.index !== subject.index))
  },

  moduleInputName(fieldName) {
    const moduleIndex = this.categoryNames.indexOf(this.category)
    return `course_outline[study_modules_attributes][${moduleIndex}][${fieldName}]`
  },

  subjectInputName(fieldName) {
    return `${this.moduleInputName('lesson_subjects_attributes')}[${this.subject.index}][${fieldName}]`
  },
})

export const curriculumTrainings = (trainings, curriculumTrainingsByTrainingId) => ({
  trainings,

  init() {
    this.trainings.forEach((training, i) => {
      const curriculumTraining = curriculumTrainingsByTrainingId[training.id]
      training.selected = !!curriculumTraining
      training.required = curriculumTraining?.required || false
      training.curriculumTrainingId = curriculumTraining?.id
      training.idx = i
    })
  },

  trainingInputName(fieldName) {
    return `course_outline[curriculum_trainings_attributes][${this.training.idx}][${fieldName}]`
  },
})
