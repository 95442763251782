import type { AlpineComponent, Stores } from 'alpinejs'

// Toggle button (icon/link) that sends an AJAX request to the specified URL
// Use an <a> tag with a link to the correct action. Add the following attributes to the element:
//   x-data="toggle(true/false)"
//   x-bind="ToggleButton"
export default (checked: boolean, method = 'patch'): AlpineComponent<{ checked: boolean; ToggleButton: Stores }> => ({
  checked,

  ToggleButton: {
    'data-remote': true,
    'data-method': method,

    [':class'](): string {
      const baseClasses = 'material-symbols-outlined toggle-icon'
      return this.checked ? `${baseClasses} checked` : baseClasses
    },

    ['@ajax:success']() {
      this.checked = !this.checked
    },

    ['x-text'](): string {
      return this.checked ? 'toggle_on' : 'toggle_off'
    },
  },
})
