export default () => ({
  filterTable(table, value, loc) {
    value = value.toUpperCase().trim()
    for (let el of document.getElementById(table).getElementsByTagName("tr")) {
      let td = el.getElementsByTagName("td")[loc ||= 1];
      if (!td) continue
      el.style.display = td.textContent.toUpperCase().includes(value) ? "" : "none"
    }
  }
})
