export default (invoiceId, origReminderDate, reload = true) => ({
  allowEdits: !origReminderDate,
  saved: !!origReminderDate,

  init() {
    const form = this.$root
    form.addEventListener('ajax:success', () => {
      this.allowEdits = false
      this.saved = true
      form.querySelector('.form-errors')?.remove()

      // Don't reload the page in the edit view
      const avoidReload = window.location.pathname.split('/').at(-1) === 'edit'
      if (!avoidReload) this.updatePage()
    })
  },

  emailSentMessage({ detail }) {
    const successMsg = detail[0]
    flash('notice', successMsg)
  },

  async remove(url) {
    const res = await fetch(url, { method: 'POST' })
    if (!res.ok || !reload) return

    this.saved = false
    this.allowEdits = true
    this.updatePage()
  },

  get newChanges() {
    return !origReminderDate || this.allowEdits
  },

  // Reload the full page in the edit view, replace table row in index view
  async updatePage() {
    const reload = window.location.pathname.split('/').at(-1) === 'edit'

    if (reload) {
      window.location.reload()
      return
    }

    const originalRow = document.querySelector(`#invoice-row-${invoiceId}`)
    const rowType = originalRow.dataset.rowType
    const res = await fetch(`/teacher/invoices/${invoiceId}/invoice_row?row_type=${rowType}`)
    originalRow.outerHTML = await res.text()
  },
})
