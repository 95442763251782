export default (cookieConsent) => ({
  cookieConsent,

  async setCookieConsent(consent) {
    try {
      const body = JSON.stringify({ consent_value: consent })
      const headers = { Accept: 'application/json', 'Content-Type': 'application/json' }
      const res = await fetch('/cookies/consent', { method: 'POST', body, headers })

      if (!res.ok) {
        const { error } = await res.json()
        throw new Error(error)
      }

      if (this.cookieConsent === 2 && consent === 1) {
        window.location.reload()
      }

      this.cookieConsent = consent
    } catch (error) {
      error.name === 'Error' ? alert(error.message) : alert(window.I18n.unknown_error)
    }
  },
})
