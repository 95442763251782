export default (inAppPurchaseSubject, teachingCardInfo, inAppPurchases, questionData) => ({
  subject: inAppPurchaseSubject,
  teachingCardInfo,
  inAppPurchases,
  questionData,

  get lessonTypeSubjects() {
    const inAppPurchase = this.inAppPurchases.find(({ id }) => id === this.subject.in_app_purchase_id)
    const subjects = inAppPurchase?.lesson_type_subjects || []

    // Select first radio button when the selectable options change
    if (!subjects.some(({ id }) => id === this.teachingCardInfo.lesson_type_subject_id)) {
      this.teachingCardInfo.lesson_type_subject_id = subjects[0]?.id
    }

    return subjects
  },

  get maximumTestScore() {
    return this.selectedQuestions.reduce((acc, question) => acc + question.get_answer_score, 0)
  },

  get selectedQuestions() {
    return this.questionData.filter(({ id }) => this.subject.driving_test_question_ids.includes(id))
  },
})
