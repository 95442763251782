import type { AlpineComponent } from 'alpinejs'

type CustomModalComponent = { modal: HTMLDialogElement | null; closeModal: () => void }

export const customModal = (): AlpineComponent<CustomModalComponent> => ({
  modal: null,

  init() {
    if (!(this.$root instanceof HTMLDialogElement)) {
      console.error('A <dialog> element is required')
      return
    }

    this.modal = this.$root
    this.modal.showModal()

    // Close the modal when clicking outside it
    // When the target is the modal element, it means that the user clicked on the backdrop
    // If either the mousedown or mouseup event happens inside the modal, the user probably didn't mean to close the modal
    this.modal.addEventListener('mousedown', ({ target: mousedownTarget }) => {
      if (mousedownTarget !== this.modal) return

      this.modal?.addEventListener(
        'mouseup',
        ({ target: mouseupTarget }) => mouseupTarget === this.modal && this.closeModal(),
        { once: true }
      )
    })

    this.modal.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') this.closeModal()
    })
  },

  closeModal() {
    this.modal?.remove()
  },
})

type CustomModalLinkComponent = { modalLink: HTMLAnchorElement | null; openModal: () => Promise<void> }
export type ModalLoadedEvent = { url: string }

// Attach to an <a> tag that links to the modal
export const customModalLink = (): AlpineComponent<CustomModalLinkComponent> => ({
  modalLink: null,

  init() {
    if (!(this.$root instanceof HTMLAnchorElement)) {
      console.error('Use on an <a> tag')
      return
    }

    this.modalLink = this.$root

    // This is a fix to a DataTable issue where links on pages 2+ open multiple modals at once
    if (this.modalLink.getAttribute('linkListenerAttached') === 'true') {
      return
    }
    this.modalLink.setAttribute('linkListenerAttached', 'true')

    this.modalLink.addEventListener('click', async (e) => {
      e.preventDefault()
      e.stopPropagation()
      await this.openModal()
    })
  },

  async openModal() {
    window.loadingSpinner(true)

    try {
      const url = this.modalLink?.href

      if (!url) {
        console.error('Modal URL missing')
        throw new Error()
      }

      const res = await fetch(url)
      const data = await res.text()

      if (!res.ok) {
        throw new Error()
      }

      document.body.insertAdjacentHTML('beforeend', data)

      const event: CustomEvent<ModalLoadedEvent> = new CustomEvent('customModalLoaded', { detail: { url } })
      document.dispatchEvent(event)
    } catch {
      alert(window.I18n.unknown_error)
    }

    window.loadingSpinner(false)
  },
})
