import type { AlpineComponent } from 'alpinejs'

// Usage: <select x-data="selectize(options)">...</select>
export const selectize = (options = {}): AlpineComponent<{}> => ({
  init() {
    const selectBox = this.$root

    // Dispatch a change event on the original select box and extend with provided options
    $(selectBox).selectize({
      onChange: () => selectBox.dispatchEvent(new Event('change')),
      ...options,
    })
  },
})

// Usage: <input type="text" x-data="flatpickrInput(options)" />
// NOTE: flatpickr works quite poorly inside <dialog> elements (mostly problematic on smaller screens)
//       Test with ~200% zoom to make sure that it's okay on small screens
//       Use native HTML date / datetime inputs if responsiveness is an issue
export const flatpickrInput = (type = 'date', options = {} as { [index: string]: any }): AlpineComponent<{}> => ({
  init() {
    // Regular flatpickr instances will show up behind <dialog> elements, using the 'static' option fixes that
    // By default, flatpickr instances would be appended to <body> and therefore be outside the dialog, which is on the top layer (highest possible layer in the browser stacking context)
    if (this.$root.closest('dialog')) {
      options.static = true
    }

    switch (type) {
      case 'datetime':
        options.enableTime = true
        options.altFormat = 'j.n.Y H:i'
        options.dateFormat = 'Y-m-d H:i'
        break
      case 'date':
        options.altFormat = 'j.n.Y'
        options.dateFormat = 'Y-m-d'
    }

    window.flatpickr(this.$root, { altInput: true, ...options })
  },
})

// Usage: <table x-data="dataTable(settings)" />
export const dataTable = (customSettings = {}): AlpineComponent<{}> => ({
  init() {
    // DataTables re-renders the table when converting it to a DataTable
    // Avoid re-initializing the table on that initial re-render
    if (this.$root.classList.contains('dataTable')) return

    const defaultSettings = {
      tableTools: { sRowSelect: 'multi', sSelectedClass: 'row-selected' },
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      responsive: true,
      order: [[0, 'desc']],
    }

    $(this.$root).DataTable({ ...defaultSettings, ...customSettings })
  },
})
