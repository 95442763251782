// Simple component for basic modal (remote) forms that don't need any fancy submit logic
// To make the form submissions remote, use "form_with" and "local: false" OR "form_for" and "remote: true"
// Successful form submission reloads the page (unless otherwise specified), errors get rendered at the top of the form without reloading
export default (reload = true, followRedirectPath = false) => ({
  init() {
    const form = this.$root

    form.addEventListener('ajax:success', ({ detail }) => {
      if (!reload) return

      // By default, the page is refreshed, but if the server responds with a URL (such as the URL of a newly created resource), that can also be followed
      const redirectPath = detail[0]
      followRedirectPath ? window.location.assign(redirectPath) : window.location.reload()
    })

    form.addEventListener('ajax:error', ({ detail }) => {
      renderFormErrors(detail[0], form)
    })
  },
})
