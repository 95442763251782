import { Calendar } from '@fullcalendar/core'
import fi from '@fullcalendar/core/locales/fi'
import en from '@fullcalendar/core/locales/en-gb'
import sv from '@fullcalendar/core/locales/sv'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

const sharedCalendarConfig = {
  plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
  locales: [fi, en, sv],
  locale: { fi, en, sv }[window.lang],
  headerToolbar: {
    start: 'prev',
    center: 'title,timeGridDay,timeGridWeek,dayGridMonth',
    end: 'next',
  },
  navLinks: true,
  slotEventOverlap: false,
  slotMinTime: '06:00',
  slotMaxTime: '21:00',
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
    meridiem: false,
  },
  allDaySlot: false,
  eventMaxStack: 3,
  dayMaxEvents: 5,
  editable: false,
  height: 'auto',
  eventDisplay: 'block',
}

export const teacherCalendar = (teacherId, view, hiddenDays, currentUserTeacherId) => ({
  teacherId,
  studentId: null,
  carId: null,
  calendar: null,
  view,
  currentUserTeacherId,
  teachers: [],
  teacherHeaderHTML: '',

  init() {
    const calendarEl = this.$refs.calendar
    const availableViews = ['dayGridMonth', 'timeGridWeek', 'timeGridDay']
    if (!this.view || !availableViews.includes(this.view)) {
      this.view = 'timeGridWeek'
    }

    this.calendar = new Calendar(calendarEl, {
      ...sharedCalendarConfig,
      initialView: this.view,
      hiddenDays,
      eventSources: {
        url: '/teacher/calendar_events/search',
        extraParams: () => {
          // Only append the selected parameter as a query string
          if (this.studentId) {
            return { student_id: this.studentId }
          } else if (this.carId) {
            return { car_id: this.carId }
          } else {
            return { teacher_id: this.teacherId }
          }
        },
        success: () => {
          if (!this.teacherId) return

          $.get(`/teacher/calendar_events/all_teachers?teacher_id=${this.teacherId}`, (data) => {
            this.teachers = data.teachers
            this.teacherHeaderHTML = data.html
            this.setTeacherHeader()

            // Add teacher names to events if multiple teachers are selected
            this.calendar.getEvents().forEach((event) => {
              const eventTeacherId = event.extendedProps.teacherId
              const teacher = this.teachers.find(({ id }) => id === teacherId)

              if (teacher && eventTeacherId !== this.teacherId) {
                event.setProp('title', `${event.title}\n${teacher.name}`)
              }
            })
          })
        },
      },
      datesSet: ({ view }) => {
        this.setTeacherHeader()
        if (view.type === this.view) return

        this.view = view.type
        this.savePreferences('calendar_view', this.view)
      },
      eventClick: async ({ event }) => {
        try {
          const res = await fetch(`/teacher/calendar_events/${event.id}`)
          const html = await res.text()
          document.body.insertAdjacentHTML('beforeend', html)
        } catch (e) {
          console.error(e)
        }
      },
      dateClick: async ({ dateStr }) => {
        try {
          const res = await fetch(`/teacher/calendar_events/new?event_type=driving&time=${dateStr}`)
          const html = await res.text()
          document.body.insertAdjacentHTML('beforeend', html)
        } catch (e) {
          console.error(e)
        }
      },
      eventDidMount: ({ event, el }) => {
        const { dotColor, canOpen, teacherId } = event.extendedProps

        // Set teacher id as data-attribute so that it can be later used for teacher columns
        el.dataset.teacherId = teacherId

        // Add event dot
        if (dotColor) {
          const dot = document.createElement('div')
          const dotColorClass = { green: 'bg-green', red: 'bg-red', yellow: 'bg-yellow-300' }[dotColor]
          dot.classList.add('status-dot', 'status-dot-inside', 'status-dot-shadow', dotColorClass)
          el.append(dot)
        }

        if (!canOpen) el.style.filter = 'brightness(50%)'
      },
    })

    this.calendar.render()

    document.addEventListener('reloadEvents', () => {
      this.calendar.refetchEvents()
    })

    document.addEventListener('deleteEvent', () => {
      this.calendar.refetchEvents()
    })
  },

  savePreferences(key, value) {
    const body = JSON.stringify({ key, value })
    const headers = { 'Content-Type': 'application/json' }
    fetch('/preferences', { method: 'POST', headers, body })
  },

  setTeacherHeader() {
    document.querySelector('#teacher-header')?.remove()

    const teacherCount = this.teachers.length
    const currentView = this.calendar.currentData.currentViewType

    if (teacherCount < 1 || currentView !== 'timeGridDay') {
      return
    }

    const columnHeight = document.querySelector('.fc-view-harness').clientHeight
    document.querySelector('.fc-col-header-cell').insertAdjacentHTML('beforeend', this.teacherHeaderHTML)
    document.querySelector('.teacher-column').style.height = `${columnHeight}px`

    // Offset events to line them up with the correct teacher's column
    const teacherIds = this.teachers.map(({ id }) => id)
    document.querySelectorAll('[data-teacher-id]').forEach((eventElement) => {
      const teacherId = +eventElement.dataset.teacherId
      const teacherIndex = teacherIds.indexOf(teacherId)
      if (teacherIndex < 0) return

      const container = eventElement.closest('.fc-timegrid-event-harness')
      container.style.transition = 'none'
      container.style.width = `calc(${100.0 / teacherCount}% - 20px)`

      // Apply left inset as a percentage based on the teacher's index in the teacher header to push the events in the correct virtual "column"
      const insetValues = container.style.inset.split(' ')
      const leftInset = `${(teacherIndex / teacherCount) * 100}%`
      insetValues.length > 3 ? (insetValues[3] = leftInset) : insetValues.push(leftInset)
      container.style.inset = insetValues.join(' ')
    })
  },

  changeFilters() {
    const id = this.$el.id

    // Empty all other selects but the currently active one
    if (id !== 'teacher_id') {
      this.teacherId = null
    }

    if (id !== 'student_id') {
      this.studentId = null
    }

    if (id !== 'car_id') {
      this.carId = null
    }

    if (this.teacherId) {
      this.saveTeacherSelection()
    }

    // Update events
    this.calendar.refetchEvents()
  },

  saveTeacherSelection() {
    // Only store selected teacher if it's either the current user or all teachers (negative value)
    // Showing a single teacher that's not the current user by default might be confusing
    if (this.teacherId < 0 || this.teacherId === this.currentUserTeacherId) {
      this.savePreferences('calendar_teacher_id', this.teacherId)
    }
  },
})

export const studentCalendar = () => ({
  init() {
    const calendar = new Calendar(this.$refs.calendar, {
      ...sharedCalendarConfig,
      initialView: 'timeGridWeek',
      events: '/student/calendar_events.json',
      eventClick: async ({ event }) => {
        const res = await fetch(`/student/calendar_events/${event.id}`)
        const html = await res.text()
        document.body.insertAdjacentHTML('beforeend', html)
      },
    })

    calendar.render()
  },
})
