export default () => ({
  license: null,
  start: null,

  async search() {
    const form = this.$el.form

    try {
      const res = await fetch(form.action, { method: 'POST', body: new FormData(form) })
      if (!res.ok) throw new Error()
      document.querySelector('#available-courses').innerHTML = await res.text()
    } catch {
      alert(I18n.unknown_error)
    }
  },

  // FormElement.reset() doesn't work with selectize, so selectize's own clear() method must be used instead
  resetForm() {
    document.querySelectorAll('#search-form select').forEach((s) => s.selectize.clear())
  },
})
