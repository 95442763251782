export default (htmlTargetSelector) => ({
  pageLinks: null,

  // Set up pagy links as remote (ajax) links and update data in the target container dynamically
  init() {
    const container = document.querySelector(htmlTargetSelector)
    this.pageLinks = this.$root.querySelectorAll('.page-link')
    this.pageLinks.forEach((pageLink) => {
      pageLink.dataset.remote = 'true'
      pageLink.addEventListener('ajax:success', ({ detail }) => {
        container.innerHTML = detail[2].responseText
      })
    })
  },

  // Select a new item count and go back to page 1 with the updated count
  changeItemCount() {
    const link = this.pageLinks[1]
    const url = new URL(link.href)
    url.searchParams.set('items', this.$el.value)
    link.href = url.toString()
    link.click()
  },
})
