export default (contractsByPurchase) => ({
  contractsByPurchase,
  purchaseSelects: document.querySelectorAll('.purchases-select'),

  init() {
    document.addEventListener('purchaseAdded', ({ detail: purchase }) => {
      this.contractsByPurchase[purchase.id] = 0
      for (const select of this.purchaseSelects) {
        const option = document.createElement('option')
        option.value = purchase.id
        option.innerText = purchase.name
        select.appendChild(option)
      }
    })

    document.addEventListener('contractAdded', ({ detail }) => {
      const { html, msg, purchaseId } = detail
      document.querySelector('#contracts-table tbody').insertAdjacentHTML('afterbegin', html)
      this.contractsByPurchase[purchaseId]++
      flash('notice', msg)
    })
  },

  async addPurchaseToContract(purchaseId, contractId) {
    const url = `/teacher/contracts/${contractId}/link_purchase`
    const body = JSON.stringify({ purchase_id: purchaseId })
    const headers = { 'Content-Type': 'application/json' }

    try {
      const res = await fetch(url, { method: 'PUT', body, headers })
      const { html, msg } = await res.json()
      if (!res.ok) throw new Error(msg)

      this.contractsByPurchase['']--
      this.contractsByPurchase[purchaseId]
        ? this.contractsByPurchase[purchaseId]++
        : (this.contractsByPurchase[purchaseId] = 1)

      this.$el.closest('tr').outerHTML = html
      flash('notice', msg)
    } catch (message) {
      console.error(message)
      alert(message || 'Something went wrong')
    }
  },

  async connectPaymentToPurchase(purchaseId, paymentId) {
    try {
      const url = `/teacher/purchases/${purchaseId}/connect_payment?payment_id=${paymentId}`
      const res = await fetch(url, { method: 'POST' })
      if (!res.ok) throw new Error()

      location.reload()
    } catch {
      alert(I18n.unknown_error)
    }
  },

  removeContract(purchaseId) {
    this.contractsByPurchase[purchaseId]--
    this.$el.closest('tr').remove()
    flash('notice', this.$event.detail[0])
  },

  removePurchase(purchaseId) {
    delete this.contractsByPurchase[purchaseId]
    this.$el.closest('tr').remove()
    flash('notice', this.$event.detail[0])
    const contractRows = document.querySelectorAll(`tr[data-purchase-id="${purchaseId}"]`)
    contractRows.forEach((row) => row.remove())
    for (const select of this.purchaseSelects) {
      select.querySelector(`option[value="${purchaseId}"]`)?.remove()
    }
  },

  get hasPurchases() {
    return Object.keys(this.contractsByPurchase).length > 0
  },

  get hasContracts() {
    return Object.values(this.contractsByPurchase).some((val) => val > 0)
  },
})
